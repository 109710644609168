<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Add Schema Online
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <b-col md="6">
            <b-form-group
              label="Category"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="category"
              >
                <v-select
                data-testid="category-online-select"
                  v-model="addCourseForm.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="AllcategoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(addCourseForm.category)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
       

          <b-col

            md="6"
          >

            <b-form-group
              label="Select Schema"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="cate"
                rules=""
              >

                <v-select
                data-testid="schema-online-select"
                  v-model="addCourseForm.schemaable_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="categoriesList"
                  @input="getSchema(addCourseForm.schemaable_id)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-col md="12">
              <b-form-group
                label="Name"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                  data-testid="schema-course-name"
                    v-model="courseData.name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Category"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="cate"
                  rules=""
                >
                  <b-form-input
                  data-testid="schema-category-name"
                    v-model="courseData.category"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Location Name"
                label-for="location-name"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="locationName"
                  rules=""
                >
                  <b-form-input
                  data-testid="schema-location-name"
                    v-model="courseData.location.name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Aggregate Rating"
                label-for="aggregate-rating"
                class="mb-2"
              >
                <b-form-input
                data-testid="schema-rating"
                  v-model="courseData.aggregateRating.ratingValue"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Aggregate Rating Count"
                label-for="aggregate-rating"
                class="mb-2"
              >
                <b-form-input
                data-testid="schema-count"
                  v-model="courseData.aggregateRating.ratingCount"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Aggregate Review Count"
                label-for="aggregate-rating"
                class="mb-2"
              >
                <b-form-input
                data-testid="schema-review"
                  v-model="courseData.aggregateRating.reviewCount"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <!-- Course Information -->

            <b-col md="12">
              <b-form-group
                label="Offer Type"
                label-for="offer-type"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="offerType"
                  rules=""
                >
                  <b-form-input
                  data-testid="schema-offer-type"
                    v-model="courseData.offers['@type']"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

         

            <b-col md="12">
              <b-form-group
                label="Offer Price"
                label-for="offer-price"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="offerPrice"
                  rules=""
                >
                  <b-form-input
                  data-testid="schema-offer-price"
                    v-model="courseData.offers.price"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Offer Category"
                label-for="offer-price"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="offerPrice"
                  rules=""
                >
                  <b-form-input
                  data-testid="schema-offer-category"
                    v-model="courseData.offers.category"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Has Course Instance -->
            <b-col md="12">
              <b-form-group
                label="provider Type"
                label-for="course-images"
                class="mb-2"
              >
               Organization
              </b-form-group>
            </b-col>
          <b-col md="12">
              <b-form-group
                label="provider Name"
                label-for="course-images"
                class="mb-2"
              >
                <b-form-input
                data-testid="schema-provider-name"
                  v-model="courseData.provider.name"
                  
                />
              </b-form-group>
            </b-col><b-col md="12">
              <b-form-group
                label="provider URL"
                label-for="course-images"
                class="mb-2"
              >
                <b-form-input
                data-testid="schema-provider-url"
                  v-model="courseData.provider.url"
                  
                />
              </b-form-group>
            </b-col>
          </b-col>
        
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
            data-testid="schema-course-submit-button"
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>

import { watch, ref, reactive } from '@vue/composition-api'
import {
  BCard,

  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,

  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

import Vue from 'vue'
import store from '@/store'

export default {
  setup(props, { emit }) {
    // const store = useStore();
       const isLoading = ref(false)
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('')
    const coursesList = ref([])
    const categoriesList = ref([])
    const scheduleList = ref([])
    const citiesList = ref([])
    const addCourseForm = reactive({
      category_id: '',
      type: 'Modules\\Course\\Entities\\Course',
      course: '',
      city: '',
      schedule: '',
      slider: '',
    })
    store.dispatch('cities/cititesList').then(response => {
      citiesList.value = response.data
    })
    const dedicated = ref(false)
    const AllcategoriesList = ref([])
    store.dispatch('categories/CategoryList')
      .then(response => {
        AllcategoriesList.value = response.data
      })

    const getCourseSchedule = id => {
      categoriesList.value = []
      if (id == 'Modules\\Course\\Entities\\Course') {
        dedicated.value = true
        store.dispatch('courses/getAllCoursesList').then(response => {
          response.data.forEach(el => {
            if (el.online == 1) {
              categoriesList.value.push({
                ...el,
                id: el.id,
                name: `${el.name} Online`,
                link_id: el.link_id,
              })
            } else {
              categoriesList.value.push({
                ...el,
                id: el.id,
                name: el.name,
                link_id: el.link_id,
              })
            }
          })
        })
      } else if (id == 'Modules\\Event\\Entities\\Event') {
        store.dispatch('events/Allevent').then(response => {
          categoriesList.value = response.data
        })
      } else {
        store.dispatch('blog/getAllBlog').then(response => {
          // categoriesList.value = response.data.data
    
          categoriesList.value = response.data.data.map(el => ({
            name: el.title,
            id: el.id,
            schema: el.schema,
          }))
        })
      }
    }

    const getscheduleByCourse = (cityid, courseid) => {
      store
        .dispatch('homepage/getscheduleByCourse', { cityid, courseid })
        .then(response => {
          scheduleList.value = response.data
        })
    }
    const getscheduleByCourseOnline = (cityid, courseid) => {
      if (addCourseForm.type == 'online') {
        store
          .dispatch('homepage/getscheduleByCourse', { cityid, courseid })
          .then(response => {
            scheduleList.value = response.data
          })
      }
    }
    const typeList = [
      {
        name: 'Post',
        id: 'Modules\\DynamicContent\\Entities\\Post',
      },
      {
        name: 'Course',
        id: 'Modules\\Course\\Entities\\Course',
      },

      {
        name: 'Event',
        id: 'Modules\\Event\\Entities\\Event',
      },
    ]

    const getCoursesByCategory = id => {
      store
        .dispatch('courses/getAllCoursesList', {
          'filter[category_id]': id,
          'filter[online]': 1,
        })
        .then(response => {
          categoriesList.value = response.data
        })
    }
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
        
          formData.append('schemaable_type', 'Modules\\Course\\Entities\\Course')
          formData.append('schemaable_id', addCourseForm.schemaable_id.id)

          formData.append('data', JSON.stringify(courseData.value))
  isLoading.value = true
          store
            .dispatch('homepage/addSchema', formData)
            .then(response => {
                isLoading.value = false
              addCourseForm.schedule = ''
              addCourseForm.type = ''
              addCourseForm.slider = ''
              addCourseForm.course = ''
              addCourseForm.category_id = ''
              addCourseForm.city = ''
              addCourseForm.slider = ''

              emit('add-course')
              Vue.swal({
                title: ' Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const CityId = ref(null)

    const getSchema = item => {
      store
          .dispatch('courses/getSchemaCourseOnline', {
            course: item.id,
        
          })
          .then(response => {
    
            courseData.value = JSON.parse(response.data.schema)
          })
    }
    watch(CityId, val => {
      getSchema(addCourseForm.schemaable_id)
    })
    const courseData = ref({

      '@context': 'https://schema.org/',
      '@id': '',
      '@type': 'Course',
      name: '',
      description: '',
      category: '',

      provider: {
        '@type': 'Organization',
        name: '',
        url: '',
      },

      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: 0,
        ratingCount: 0,
        reviewCount: 0,
      },
      offers: [
        {
          '@type': 'Offer',
          category: '',
          priceCurrency: '',
          price: 0,
        },
      ],
      location: {
        '@type': 'City',
        name: '',
      },
      hasCourseInstance: [
        {
          '@type': 'CourseInstance',
          courseMode: '',
          location: '',
          courseSchedule: {
            '@type': 'Schedule',
            duration: '',
            repeatFrequency: '',
            repeatCount: 0,
            startDate: '',
            endDate: '',
          },
          instructor: [
            {
              '@type': 'Person',
              name: '',
              description: '',
              image: '',
            },
          ],
        },
        {
          '@type': 'CourseInstance',
          courseMode: '',
          courseWorkload: '',
        },
      ],

    })
    return {
    isLoading,
      getSchema,
      courseData,
      AllcategoriesList,
      CityId,
      refInputEl,
      dedicated,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      typeList,
      categoriesList,
      getCoursesByCategory,
      citiesList,
      getCourseSchedule,
      scheduleList,
      getscheduleByCourseOnline,
      getscheduleByCourse,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,

    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
